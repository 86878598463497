import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'; 
import { useNavigate } from 'react-router-dom';
import $, { data } from 'jquery';
const SendLeads = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [builderTypes, setBuilderTypes] = useState([]);
  const [selectedBuilderType, setSelectedBuilderType] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        $('#loading').show();
        const accessToken = localStorage.getItem('access_token');
  
        if (!accessToken) {
          navigate('/');
          console.error('Access token not found in localStorage');
          $('#loading').hide();
          return;
        }
  
        const response = await fetch(`${baseURL}/get_ads/`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Cache-Control': 'no-cache', // Include access token in the headers
          },
        });
  
        if (!response.ok) {
          $('#loading').hide();
        }
  
        const data = await response.json();
        if (data && data.data) {
          setBuilderTypes(data.data);
          $('#loading').hide();
        }
      } catch (error) {
        console.error('Error fetching builder types:', error);
        setErrors({ selectedBuilderType: 'Error fetching builder types' });
        $('#loading').hide();
      }
    };
  
    fetchData();
  }, []);
  

  const handleBuilderTypeChange = (event) => {
    setSelectedBuilderType(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    console.log(selectedBuilderType);
    try {
      $('#loading').show();
      const accessToken = localStorage.getItem('access_token');
  
      if (!accessToken) {
        navigate('/');
        console.error('Access token not found in localStorage');
        $('#loading').hide();
        return;
      }
  
      const response = await fetch(`${baseURL}/whats_app_lead_send/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
          'Cache-Control': 'no-cache', // Include access token in the headers
        },
        body: JSON.stringify({
          selectedBuilderType,
          // Other data you want to send in the request body
        }),
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Request failed:', errorMessage);
        toastr.error('No Leads Found');
        setSelectedBuilderType('');
        $('#loading').hide();
        // Handle the error appropriately (display error message, etc.)
      } else {
        console.log('Data submitted successfully!');
        toastr.success('All Leads Sent Successfully');
        setSelectedBuilderType('');
        $('#loading').hide();
        // Additional actions upon successful submission
      }
    } catch (error) {
      toastr.error('Something Went Wrong');
      console.error('Error submitting data:', error);
      setSelectedBuilderType('');
      $('#loading').hide();
      // Handle error (e.g., show error message)
    }
  };
  

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 p-0">
          <Sidebar />
        </div>
        <div className="col-md-9 d-flex justify-content-center align-items-center">
        <div id="loading">
  <div class="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="loader-text">Loading...</div>
  </div>
</div>
        <div className="card p-4 rounded text-center" style={{ backgroundColor: '#f0f0f0', minWidth: '636px', minHeight: '461px' }}>
            <h2>Send Leads To Whatsapp</h2>
            <div className="mb-3">
              <img src="wa7.png" height="180" alt="WhatsApp Logo" />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="ad_name" style={{ fontSize: '25px' }}>Select Ad:</label>
                <hr></hr>
                <select
                  name="ad_name"
                  id="ad_name"
                  className="form-select"
                  required
                  value={selectedBuilderType}
                  onChange={handleBuilderTypeChange}
                  
                >
                  <option value="">Select Ad</option>
                  {builderTypes.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.builder_name}
                    </option>
                  ))}
                </select>
                {errors.selectedBuilderType && (
                  <div className="error-message text-danger">
                    {errors.selectedBuilderType}
                  </div>
                )}
              </div>
              <div className="d-grid gap-2 col-6 mx-auto">
                <Button variant="success" type="submit" style={{ backgroundColor: '#2ecc71', maxWidth:'100px', marginLeft:'122px'}}>Submit</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendLeads;
