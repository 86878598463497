import React, { useState,useEffect } from 'react';
import ReactDOM from 'react-dom';
import Sidebar from '../Sidebar/Sidebar';
import $, { data } from 'jquery';
import { useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const FetchLeads = () => {
    const [selectedBuilderType, setSelectedBuilderType] = useState('');

    const baseURL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const [builderTypes, setBuilderTypes] = useState([]);

  const [adName, setAdName] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        $('#loading').show();
        const accessToken = localStorage.getItem('access_token');
  
        if (!accessToken) {
          navigate('/');
          console.error('Access token not found in localStorage');
          $('#loading').hide();
          return;
        }
  
        const response = await fetch(`${baseURL}/get_ads/`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Cache-Control': 'no-cache', // Include access token in the headers
          },
        });
  
        if (!response.ok) {
          $('#loading').hide();
        }
  
        const data = await response.json();
        if (data && data.data) {
            setBuilderTypes(data.data);
          $('#loading').hide();
        }
      } catch (error) {
        console.error('Error fetching builder types:', error);
        setErrors({ selectedBuilderType: 'Error fetching builder types' });
        $('#loading').hide();
      }
    };
    
  
    fetchData();
  }, []);

  const handleBuilderTypeChange = (event) => {
    setSelectedBuilderType(event.target.value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!selectedBuilderType) newErrors.selectedBuilderType = 'Please select an ad';
    if (!fromDate) newErrors.fromDate = 'Please select a start date';
    if (!toDate) newErrors.toDate = 'Please select an end date';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let apiUrl = '';
      apiUrl = `${baseURL}/manual_lead_fetch/`; 
    //   if (selectedBuilderType === 'all_ads') {
    //     apiUrl = `${baseURL}/v1/fetch_leads/`; // Replace with your actual URL for all_ads
    //   } else {
    //     apiUrl = `${baseURL}/get_ads/`; // Replace with your actual URL for other cases
    //   }

      const formData = {
        selectedBuilderType,
        fromDate,
        toDate
      };

      try {
        $('#loading').show();
        const accessToken = localStorage.getItem('access_token');
      
          if (!accessToken) {
            navigate('/');
            console.error('Access token not found in localStorage');
            $('#loading').hide();
            return;
          }
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${accessToken}`,
            'Cache-Control': 'no-cache', // Include access token in the headers
          },
          body: JSON.stringify(formData)
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
          $('#loading').hide();
          toastr.error('Something went wrong');
        }

        const data = await response.json();
        console.log('API Response:', data);
        $('#loading').hide();
        toastr.success(data.message);
      } catch (error) {
        console.error('Error:', error);
        alert('Failed to generate report. Please try again later.');
        $('#loading').hide();
      }
    }
  };

  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      backgroundColor: '#f0f4f8'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '500px',
      width: '100%',
      padding: '30px',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    },
    title: {
      textAlign: 'center',
      color: '#2196f3',
      marginBottom: '30px'
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '20px',
    },
    label: {
      marginBottom: '8px',
      color: '#333',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    input: {
      padding: '12px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      fontSize: '16px',
    },
    error: {
      color: '#d32f2f',
      fontSize: '14px',
      marginTop: '4px',
    },
    button: {
      padding: '14px',
      backgroundColor: '#2196f3',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '18px',
      fontWeight: 'bold',
      transition: 'background-color 0.3s',
    }
  };

  return (
    
    <div className="container-fluid">
      
    <div className="row ">
      <Sidebar />
      <main className="col-md-9 ms-sm-auto col-lg-9 px-md- mb-4 mt-4">
      <div id="loading">
  <div class="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="loader-text">Loading...</div>
  </div>
</div>
      <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <h2 style={styles.title}>Manual Lead Fetch</h2>
        
        <div style={styles.inputContainer}>
          <label htmlFor="adName" style={styles.label}>Select Ad Name:</label>
          <select
                  name="ad_name"
                  id="ad_name"
                  className="form-select"
                  required
                  value={selectedBuilderType}
                  onChange={handleBuilderTypeChange}
                  
                >
                  <option value="">Select Ad</option>
                  <option value="all_ads">All Ads</option>
                  {builderTypes.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.builder_name}
                    </option>
                  ))}
                </select>
          {errors.adName && <div style={styles.error}>{errors.adName}</div>}
        </div>

        <div style={styles.inputContainer}>
          <label htmlFor="fromDate" style={styles.label}>From Date:</label>
          <input
            type="date"
            id="fromDate"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            style={{...styles.input, borderColor: errors.fromDate ? '#d32f2f' : '#ccc'}}
          />
          {errors.fromDate && <div style={styles.error}>{errors.fromDate}</div>}
        </div>

        <div style={styles.inputContainer}>
          <label htmlFor="toDate" style={styles.label}>To Date:</label>
          <input
            type="date"
            id="toDate"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            style={{...styles.input, borderColor: errors.toDate ? '#d32f2f' : '#ccc'}}
          />
          {errors.toDate && <div style={styles.error}>{errors.toDate}</div>}
        </div>

        <button type="submit" style={styles.button}>Generate Report</button>
      </form>
    </div>
  </main>
    </div>
  </div>
  );
};

export default FetchLeads;
