import React, { useState, useEffect, useRef } from 'react';
import $, { data } from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../Sidebar/Sidebar'; // Import your Sidebar component here
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { RingLoader } from 'react-spinners'; // Import RingLoader here
import { Form, Button, Card, Col, Row,Modal  } from 'react-bootstrap';

const Accounts = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const tableRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [loading, setLoading] = useState(true); // New state for loading indicator

  const [deleteItemUsername, setDeleteItemUsername] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  
  
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    app_id: '',
    secret_key: '',
    access_token: '',
    expiry_date: '',
    app_name:'',
  });
  
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  window.handleIconClick = async (username) => {
    try {
      $('#loading').show();
      const accessToken = localStorage.getItem('access_token');
      console.log(accessToken, "token value");
  
      if (!accessToken) {
        navigate('/');
        console.error('Access token not found in localStorage');
        $('#loading').hide();
        return;
      }
  
      const response = await axios.get(`${baseURL}/get_meta_account_object/${username}`, {
        method: 'GET', // Remove this line, as 'axios.get' specifies the method already
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache', // Adjust content type if needed
        },
      });
  
      const responseData = response.data;
  
      if (responseData.data) {
        const userData = responseData.data;
  
        setFormData({
          username: userData.username,
          password: userData.password,
          app_name: userData.app_name,
          app_id: userData.app_id,
          secret_key: userData.secret_key,
          access_token: userData.access_token,
          expiry_date: userData.expiry_date,
        });
  
        setShowModal1(true);
        $('#loading').hide();
      } else {
        // Handle case when no data is received
        console.error('No data received');
        $('#loading').hide();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      $('#loading').hide();
    }
  };
  
  
  
  
  const handleSubmit = async () => {
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);
    if (emptyFields.length > 0) {
      const errors = {};
      emptyFields.forEach((field) => {
        errors[field] = 'Field is required';
      });
      setFormErrors(errors);
    } else {
      try {
        $('#loading').show();
        const accessToken = localStorage.getItem('access_token');
  
      if (!accessToken) {
        navigate('/');
        console.error('Access token not found in localStorage');
        $('#loading').hide();
        return;
      }
  
        const response = await fetch(`${baseURL}/save_meta_account/`, {
          method: 'POST',
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
          },
          body: JSON.stringify(formData),
        });
        console.log('Form submitted successfully!', response);
        const data = await response.json();
        setTableData(data.data);
        setShowModal(false);
        setFormData({
          username: '',
          password: '',
          appId: '',
          secretKey: '',
          accessToken: '',
          expiryDate: '',
          app_name :'',
        });
        setFormErrors({});
        $('#loading').hide();
      } catch (error) {
        console.error('Error submitting form:', error);
        $('#loading').hide();
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        $('#loading').show();
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
          navigate('/');
          console.error('Access token not found in localStorage');
          $('#loading').hide();
          return;
        }

        setLoading(true); // Set loading to true when fetching data starts

        const response = await fetch(`${baseURL}/get_metadata/`, {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
          },
        });

        const data = await response.json();
        setTableData(data.data);
        $('#loading').hide();
      } catch (error) {
        console.error('Error fetching data:', error);
        $('#loading').hide();
      } finally {
        $('#loading').hide(); // Set loading to false when fetching data finishes
      }
    };

    fetchData();
  }, [baseURL, navigate]);
  
  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      username: '',
      password: '',
      appId: '',
      secretKey: '',
      accessToken: '',
      expiryDate: '',
      app_name: '',
    });
    setFormErrors({}); // Reset form errors when closing the modal
  };

  window.changeStatus = async (username, row) => {
    try {
      $('#loading').show();
      const accessToken = localStorage.getItem('access_token');
  
      if (!accessToken) {
        navigate('/');
        console.error('Access token not found in localStorage');
        $('#loading').hide();
        return;
      }
  
      const response = await fetch(`${baseURL}/account_status_change/${username}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
          'Cache-Control': 'no-cache', // Include access token in the headers
        },
        body: JSON.stringify({ status: 'updated' }),
      });
  
      if (!response.ok) {
        $('#loading').hide();

        }
  
      const data = await response.json();
      console.log(`Status changed for ID: ${data.data}`);
                $('#loading').hide();

      const updatedAdItem = data.data;
  
      // Update the local state
      const updatedAdItems = tableData.map(item => {
        if (item.username === updatedAdItem.username) {
          return updatedAdItem;
        }
        return item;
      });
      
      setTableData(updatedAdItems);
      $('#loading').hide();

      toastr.success('Account Status Updated Successfully');
    } catch (error) {
      console.error('Error updating status:', error);
      $('#loading').hide();

    }
  };
  

  window.handleDelete = (username) => {
    $('#loading').show();

    if (username) {
      const accessToken = localStorage.getItem('access_token');
  
      if (!accessToken) {
        navigate('/');
        console.error('Access token not found in localStorage');
        $('#loading').hide();

        return;
      }
  
      setDeleteItemUsername(username);
      setShowDeleteConfirmation(true);
  
      fetch(`${baseURL}/delete_account/${username}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
          'Cache-Control': 'no-cache', // Include access token in the headers
        },
      })
        .then((response) => {
          if (!response.ok) {
            $('#loading').hide();

          }
          return response.json();
        })
        .then((data) => {
          console.log('Delete successful:', data);
          setTableData(data.data);
          setShowDeleteConfirmation(false);
          setDeleteItemUsername('');
          $('#loading').hide();

        })
        .catch((error) => {
          console.error('Error deleting item:', error);
          $('#loading').hide();

        });
    }
  };
  

  window.handleDeleteConfirm = (username) => {
    setDeleteItemUsername(username);
    setShowDeleteConfirmation(true);
  };


  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().destroy();
    }

    $(tableRef.current).DataTable({
      data: tableData,
      columns: [
        { title: 'USERNAME', data: 'username' },
        { title: 'Status', data: 'status', render: status => (status ? 'Active' : 'Inactive') },
        { title: 'Expiry Date', data: 'expiry_date' },
        {
          title: 'Actions',
          data: null,
          render: function (data, type, row) {
            return `
              <div class="form-check form-switch d-flex justify-content-center">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="statusSwitch-${row.username}"
                  ${row.status ? 'checked' : ''}
                  onchange="window.changeStatus('${row.username}')"
                />
                <label class="form-check-label" for="statusSwitch-${row.username}"></label>
              </div>
            `;
          },
        },
        {
          title: 'Manage',
          data: null,
          render: function (data, type, row) {
            return `
            <div class="d-flex justify-content-around">
            <i class="fas fa-edit text-primary" style="cursor: pointer;" title="Edit" onClick={window.handleIconClick('${row.username}')}></i>
            <i class="fas fa-trash-alt text-danger" style="cursor: pointer;" title="Delete" onclick="window.handleDeleteConfirm('${row.username}')"></i>  
            `;
          },
        },
      ],
    });

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [tableData]);

  


  useEffect(() => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current)
        .find('thead th')
        .css('background-color', '#9F9F9F');
    }
  }, [tableData]);

  window.handleCloseModal1 = () => {
    setShowModal1(false);
    // Reset formData or perform any necessary actions on modal close
  };
  
  const handleSubmit1 = async () => {
    $('#loading').show();

    try {
      const accessToken = localStorage.getItem('access_token');
  
      if (!accessToken) {
        navigate('/');
        console.error('Access token not found in localStorage');
        $('#loading').hide();

        return;
      }
  
      const response = await fetch(`${baseURL}/update_meta_account/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
          'Cache-Control': 'no-cache', // Include access token in the headers
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(`Status changed for ID: ${data.data}`);

        const updatedAdItem = data.data;
  
        // Update the local state
        const updatedAdItems = tableData.map(item => {
          if (item.username === updatedAdItem.username) {
            return updatedAdItem;
          }
          return item;
        });
        
        setTableData(updatedAdItems);
        $('#loading').hide();

        toastr.success('Account Updated Successfully');
  
        // Reset formData state or perform any other necessary actions after successful submission
        setFormData({
          username: '',
          password: '',
          appName: '',
          appId: '',
          secretKey: '',
          accessToken: '',
          expiryDate: '',
        });
  
        setShowModal1(false); // Close the modal after successful submission
      } else {
        console.error('Failed to submit form data');
        $('#loading').hide();

        // Handle errors if the submission fails
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
      $('#loading').hide();

      // Handle errors if the submission fails
    }
  };
  
  

  return (
    <div className="container-fluid">
      <div className="row">
      <Sidebar />
        <main className="col-md-9 ms-sm-auto col-lg-9 px-md-3">
          <h2 className="text-center mt-3">Accounts</h2>
          <div className="mb-3">
            <button type="button" className="btn btn-primary" onClick={() => setShowModal(true)}>
              Add Account
            </button>
          </div>
          <div id="loading">
  <div class="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="loader-text">Loading...</div>
  </div>
</div>

        <div className="table-responsive">
          <table
            id="table_container"
            ref={tableRef}
            className="table table-bordered table-striped"
            style={{ width: '100%' }}
          />
        </div>

          <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Deletion</Modal.Title>
  </Modal.Header>
  <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
      Cancel
    </Button>
    <Button variant="danger" onClick={() => window.handleDelete(deleteItemUsername)}>
      Delete
    </Button>
  </Modal.Footer>
</Modal>


          {/* Modal */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
  <Form>
    <Form.Group controlId="username">
      <Form.Label><strong>Username</strong></Form.Label>
      <Form.Control
        type="text"
        name="username"
        value={formData.username}
        onChange={handleInputChange}
        placeholder="Enter username"
      />
      {formErrors.username && (
        <span style={{ color: 'red', fontSize: '0.8em' }}>{formErrors.username}</span>
      )}
    </Form.Group>
    <Form.Group controlId="password">
      <Form.Label><strong>Password</strong></Form.Label>
      <Form.Control
        type="password"
        name="password"
        value={formData.password}
        onChange={handleInputChange}
        placeholder="Enter password"
      />
      {formErrors.password && (
        <span style={{ color: 'red', fontSize: '0.8em' }}>{formErrors.password}</span>
      )}
    </Form.Group>
    <Form.Group controlId="App_name">
      <Form.Label><strong>App Name</strong></Form.Label>
      <Form.Control
        type="text"
        name="app_name"
        value={formData.app_name}
        onChange={handleInputChange}
        placeholder="Enter Secret Key"
      />
      {formErrors.app_name && (
        <span style={{ color: 'red', fontSize: '0.8em' }}>{formErrors.app_name}</span>
      )}
    </Form.Group>
    <Form.Group controlId="appId">
      <Form.Label><strong>App ID</strong></Form.Label>
      <Form.Control
        type="text"
        name="app_id"
        value={formData.app_id}
        onChange={handleInputChange}
        placeholder="Enter App ID"
      />
      {formErrors.app_id && (
        <span style={{ color: 'red', fontSize: '0.8em' }}>{formErrors.app_id}</span>
      )}
    </Form.Group>
    <Form.Group controlId="secretKey">
      <Form.Label><strong>Secret Key</strong></Form.Label>
      <Form.Control
        type="text"
        name="secret_key"
        value={formData.secret_key}
        onChange={handleInputChange}
        placeholder="Enter Secret Key"
      />
      {formErrors.secret_key && (
        <span style={{ color: 'red', fontSize: '0.8em' }}>{formErrors.secret_key}</span>
      )}
    </Form.Group>
    
    <Form.Group controlId="accessToken">
      <Form.Label><strong>Access Token</strong></Form.Label>
      <Form.Control
        type="text"
        name="access_token"
        value={formData.access_token}
        onChange={handleInputChange}
        placeholder="Enter Access Token"
      />
      {formErrors.access_token && (
        <span style={{ color: 'red', fontSize: '0.8em' }}>{formErrors.access_token}</span>
      )}
    </Form.Group>
    <Form.Group controlId="expiryDate">
      <Form.Label><strong>Expiry Date</strong></Form.Label>
      <Form.Control
        type="date"
        name="expiry_date"
        value={formData.expiry_date}
        onChange={handleInputChange}
        placeholder="Select Expiry Date"
      />
      {formErrors.expiry_date && (
        <span style={{ color: 'red', fontSize: '0.8em' }}>{formErrors.expiry_date}</span>
      )}
    </Form.Group>
  </Form>
</Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showModal1} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Edit Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  <Form>
    <Form.Group controlId="username1">
      <Form.Label><strong>Username</strong></Form.Label>
      <Form.Control
        type="text"
        name="username"
        value={formData.username}
        onChange={(e) => setFormData({ ...formData, username: e.target.value })}
        readOnly
      />
    </Form.Group>
    <Form.Group controlId="password1">
      <Form.Label><strong>Password</strong></Form.Label>
      <Form.Control
        type="password"
        name="password"
        value={formData.password}
        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
      />
    </Form.Group>
    <Form.Group controlId="appName">
      <Form.Label><strong>App Name</strong></Form.Label>
      <Form.Control
        type="text"
        name="app_name"
        value={formData.app_name}
        onChange={(e) => setFormData({ ...formData, app_name: e.target.value })}
      />
    </Form.Group>
    <Form.Group controlId="appId1">
      <Form.Label><strong>App ID</strong></Form.Label>
      <Form.Control
        type="text"
        name="app_id"
        value={formData.app_id}
        onChange={(e) => setFormData({ ...formData, app_id: e.target.value })}
      />
    </Form.Group>
    <Form.Group controlId="secretKey1">
      <Form.Label><strong>Secret Key</strong></Form.Label>
      <Form.Control
        type="text"
        name="secret_key"
        value={formData.secret_key}
        onChange={(e) => setFormData({ ...formData, secret_key: e.target.value })}
      />
    </Form.Group>
    <Form.Group controlId="accessToken1">
      <Form.Label><strong>Access Token</strong></Form.Label>
      <Form.Control
        type="text"
        name="access_token"
        value={formData.access_token}
        onChange={(e) => setFormData({ ...formData, access_token: e.target.value })}
      />
    </Form.Group>
    <Form.Group controlId="expiryDate1">
      <Form.Label><strong>Expiry Date</strong></Form.Label>
      <Form.Control
        type="date"
        name="expiry_date"
        value={formData.expiry_date}
        onChange={(e) => setFormData({ ...formData, expiry_date: e.target.value })}
      />
    </Form.Group>
  </Form>
</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={window.handleCloseModal1}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit1}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
        </main>
      </div>
    </div>
  );
};

export default Accounts;
