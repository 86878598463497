import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Header = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const accessToken = localStorage.getItem('access_token');
  const navigate = useNavigate();
  const handleLogout = () => { 
    if (!accessToken) {
      navigate('/');
      console.error('Access token not found in localStorage');
      return;
    }

 // Retrieve the token from localStorage or a global variable
    console.log(localStorage.getItem('access_token'))
  fetch(`${baseURL}/drf-logout/`, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${accessToken}`, // Add your authorization token if required
      'Content-Type': 'application/json',
    },
    // You can include additional data in the body if needed
    body: JSON.stringify({ access_token: localStorage.getItem('access_token') }), // Include the refresh token if required
  })
  .then(response => {
    // Handle response according to your needs
    if (response.ok) {
      // Logout was successful
      // Perform any necessary actions like clearing local storage, redirecting, etc.
      console.log('Logout successful');
      localStorage.removeItem("access_token");
      navigate('/');

    } else {
      // Logout failed, handle accordingly
      console.error('Logout failed');
    }
  })
  .catch(error => {
    // Handle any errors during the logout process
    console.error('Error during logout:', error);
  });

};

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark">
      <div className="container">
        <Link className="navbar-brand text-white" to="/">TMS Lead Management</Link>

        <div className="dropdown ml-auto">
          {accessToken ? (
            <React.Fragment>
              <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="tms.jpeg" alt="" width="32" height="32" className="rounded-circle me-2" />
                <strong>TMS</strong>
              </a>
              <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                <li><a className="dropdown-item" href="#" onClick={handleLogout}>Sign out</a></li>
              </ul>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </nav>
  );
};

export default Header;
