    import React, { useState, useEffect } from 'react';

    import Sidebar from '../Sidebar/Sidebar';
    import toastr from 'toastr';
    import 'toastr/build/toastr.min.css'; 
    import { useNavigate } from 'react-router-dom';
    import $, { data } from 'jquery';

    const DownloadLeads = () => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();

    const [builderTypes, setBuilderTypes] = useState([]);
    const [selectedBuilderType, setSelectedBuilderType] = useState('');
    const [errors, setErrors] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    useEffect(() => {
        const fetchData = async () => {
          try {
            $('#loading').show();
            const accessToken = localStorage.getItem('access_token');
      
            if (!accessToken) {
              navigate('/');
              console.error('Access token not found in localStorage');
              $('#loading').hide();
              return;
            }
      
            const response = await fetch(`${baseURL}/get_ads/`, {
              headers: {
                Authorization: `Token ${accessToken}`,
                'Cache-Control': 'no-cache', // Include access token in the headers
              },
            });
      
            if (!response.ok) {
              $('#loading').hide();
            }
      
            const data = await response.json();
            if (data && data.data) {
              setBuilderTypes(data.data);
              $('#loading').hide();
            }
          } catch (error) {
            console.error('Error fetching builder types:', error);
            setErrors({ selectedBuilderType: 'Error fetching builder types' });
            $('#loading').hide();
          }
        };
      
        fetchData();
      }, []);
      

      const handleFromDateChange = async (event) => {
        setFromDate(event.target.value);
        try {
          $('#loading').show();
          const accessToken = localStorage.getItem('access_token');
      
          if (!accessToken) {
            navigate('/');
            console.error('Access token not found in localStorage');
            $('#loading').hide();
            return;
          }
      
          const response = await fetch(`${baseURL}/get_lead_count/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${accessToken}`,
              'Cache-Control': 'no-cache', // Include access token in the headers
            },
            body: JSON.stringify({
              adName: selectedBuilderType,
              fromDate: fromDate,
              toDate: event.target.value,
              checkboxValue: isChecked,
            }),
          });
      
          if (!response.ok) {
            $('#loading').hide();
          }
      
          const data = await response.json();
          console.log(data);
          setResponseData(data);
          $('#loading').hide();
          // Handle any other logic based on the response
        } catch (error) {
          console.error('Error fetching data:', error);
          $('#loading').hide();
          // Handle error state or display an error message
        }
      };
      
    
      const handleToDateChange = async (event) => {
        setToDate(event.target.value);
      
        try {
          $('#loading').show();
          const accessToken = localStorage.getItem('access_token');
      
          if (!accessToken) {
            navigate('/');
            console.error('Access token not found in localStorage');
            $('#loading').hide();
            return;
          }
      
          const response = await fetch(`${baseURL}/get_lead_count/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${accessToken}`,
              'Cache-Control': 'no-cache', // Include access token in the headers
            },
            body: JSON.stringify({
              adName: selectedBuilderType,
              fromDate: fromDate,
              toDate: event.target.value,
              checkboxValue: isChecked,
            }),
          });
      
          if (!response.ok) {
            $('#loading').hide();
          }
      
          const data = await response.json();
          console.log(data);
          setResponseData(data);
          $('#loading').hide();
          // Handle any other logic based on the response
        } catch (error) {
          console.error('Error fetching data:', error);
          $('#loading').hide();
          // Handle error state or display an error message
        }
      };
      
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    // Define handleBuilderTypeChange function to update selectedBuilderType
    const handleBuilderTypeChange = (event) => {
        setSelectedBuilderType(event.target.value);
    };

    const handleSendButtonClick = async (event) => {
        event.preventDefault();         
      
        try {
          $('#loading').show();
          const accessToken = localStorage.getItem('access_token');
      
          if (!accessToken) {
            navigate('/');
            console.error('Access token not found in localStorage');
            $('#loading').hide();
            return;
          }
      
          const response = await fetch(`${baseURL}/send_lead_file/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${accessToken}`,
              'Cache-Control': 'no-cache', // Include access token in the headers
            },
            body: JSON.stringify({
              adName: selectedBuilderType,
              fromDate: fromDate,
              toDate: toDate,
              checkboxValue: isChecked,
              // Other necessary data for the API call
            }),
          });
      
          if (response.ok) {
            toastr.success('File sent successfully');
            $('#loading').hide();
          } else {
            toastr.error('Data not found in the specified range');
            $('#loading').hide();
          }
      
          // Handle success logic after sending the file
        } catch (error) {
          console.error('Error sending file:', error);
          $('#loading').hide();
          // Handle error state or display an error message
        }
      };
      
    
    

      const handleDownloadButtonClick = async (event) => {
        event.preventDefault();     
        try {
          $('#loading').show();
          const accessToken = localStorage.getItem('access_token');
      
          if (!accessToken) {
            navigate('/');
            console.error('Access token not found in localStorage');
            $('#loading').hide();
            return;
          }
      
          const response = await fetch(`${baseURL}/download_pdf_file/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${accessToken}`,
              'Cache-Control': 'no-cache', // Include access token in the headers
            },
            body: JSON.stringify({
              adName: selectedBuilderType,
              fromDate: fromDate,
              toDate: toDate,
              // Other necessary data for the API call
            }),
          });
      
          if (!response.ok) {
            $('#loading').hide();
          }
      
          // Assuming the response includes the PDF file, you can convert it to a blob and create a URL for downloading
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
      
          // Create a link element to trigger the download
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'leads.xlsx');
          document.body.appendChild(link);
      
          // Trigger the download
          link.click();
      
          // Cleanup: remove the link and revoke the URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          $('#loading').hide();
        } catch (error) {
          console.error('Error downloading file:', error);
          $('#loading').hide();
          // Handle error state or display an error message
        }
      };
      
    
    
    return (
        <div className="container-fluid">
        <div className="row">
            <div className="col-md-3 p-0">
            <Sidebar />
            </div>
            <div className="col-md-9 d-flex justify-content-center align-items-center">
            <div id="loading">
  <div class="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="loader-text">Loading...</div>
  </div>
</div>
            <div className="card p-4 rounded text-center" style={{ backgroundColor: '#f0f0f0', minWidth: '753px', minHeight: '453px' }}>
                <h2>Get Leads In Pdf</h2>
                <div className="contact-box">
                <div className="right">
                    <form encType="multipart/form-data">
                    <label htmlFor="ad_name" style={{ fontSize: '18px' }}>Page name:</label>
                    <select
                        name="ad_name"
                        id="ad_name"
                        className="form-select"
                        required
                        value={selectedBuilderType}
                        onChange={handleBuilderTypeChange} // Use the defined function here
                    >
                        <option value="">Select Ad</option>
                        {builderTypes.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.builder_name}
                        </option>
                        ))}
                    </select>
                    <br />
                    <label>From Date</label>
                    <input type="date" id="startdate" name="startdate" className="form-control" placeholder="Please Enter Start date" required onChange={handleFromDateChange}/>
                    <br />
                    <label>To Date</label>
                    <input type="date" id="enddate" name="enddate" className="form-control" placeholder="Please Enter End date" required onChange={handleToDateChange} />
                    <br></br>
                    {responseData && ( // Conditionally render based on responseData
                    <div>
                      <label>Lead Count:</label>
                      <input type="text" className="form-control" value={responseData.data} readOnly />
                    </div>
                  )}
                  <br></br>
                    {/* <input type="checkbox" id="checkbox" onChange={handleCheckboxChange} name="getown" value="" /> */}
                    
                    {/* <span style={{ paddingLeft: '10px' }}>Get pdf on: +91-9325201010</span> */}
                    <div className="d-flex justify-content-between">
                    {/* <button className="btn btn-success" onClick={handleSendButtonClick}>Send File</button> */}
                        <button className="btn btn-success" onClick={handleDownloadButtonClick}>Download</button>
                    </div>
                    
                    </form>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
    }

    export default DownloadLeads;
