import React, { useState } from 'react';
import { RingLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import $ from 'jquery';
const Login = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  

  const handleLogin = (e) => {
    e.preventDefault();
    $('#loading').show();
  
    const apiUrl = `${baseURL}/login-drf/`;
    const requestBody = {
      username: username,
      password: password,
    };
  
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Invalid Credentials');
        }
        return response.json();
      })
      .then(data => {
        console.log('Login successful:', data);
        localStorage.setItem('access_token', data.access_token);
        $('#loading').hide();
        if (data.status === true) {
          navigate('/home');
        } else {
          throw new Error('Invalid Credentials');
        }
      })
      .catch(error => {
        console.error('Error during login:', error.message);
        toastr.error(error.message);
      })
      .finally(() => {
        $('#loading').hide();
      });
  };
  

  return (
    <div className='vh-100 d-flex align-items-center'>
      
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-8 col-xs-10 mx-auto">
          <div id="loading">
  <div class="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="loader-text">Loading...</div>
  </div>
</div>
            <div id="loginFrame" className="position-relative">
              <div id="login" className={`levelUp shadow bg-white card p-3 ${loading ? 'blur-none' : ''}`}>
                <h3 className="my-2">Login</h3>
                <form onSubmit={handleLogin} className='mx-3 my-3 form-content'>
                  <div className="mb-3">
                    <label htmlFor="email">Username</label>
                    <input
                      type="text"
                      className="mt-2 form-control"
                      id="username"
                      name="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="mt-2 form-control"
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <input type="checkbox" className="me-2" id="rememberMe" /> <label htmlFor="rememberMe">Remember Me</label>
                  </div>
                  <div className="mb-2">
                    <button className="btn btn-lg btn-success w-100">Login</button>
                  </div>
                  <div className="mt-4 text-center">
                    {/* <a href="">Forgot Password?</a> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
